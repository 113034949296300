.navbar-ncs {
  background-image: -webkit-linear-gradient(top, #5b7482 0, #465964 100%);
  background-image: -o-linear-gradient(top, #5b7482 0, #465964 100%);
  background-image: linear-gradient(to bottom, #5b7482 0, #465964 100%);
  .navbar-brand {
    color: $navbar-ncs-active-color;

    &:focus,
    &:hover {
      color: $navbar-ncs-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-ncs-color;

      &:focus,
      &:hover {
        color: $navbar-ncs-hover-color;
      }

      &.disabled {
        color: $navbar-ncs-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-ncs-active-color;
    }
  }

  .navbar-text {
    color: $navbar-ncs-color;
  }
}


.LOCAL {
  .navbar-ncs{
    background-image: -webkit-linear-gradient(top, #5c005c 0, #640053 100%);
    background-image: -o-linear-gradient(top, #5c005c 0, #640053 100%);
    background-image: linear-gradient(to bottom, #5c005c 0, #640053 100%);
  }
}

.DEVELOPMENT {
  .navbar-ncs{
    background-image: -webkit-linear-gradient(top, #97000e 0, #640009 100%);
    background-image: -o-linear-gradient(top, #97000e 0, #640009 100%);
    background-image: linear-gradient(to bottom, #97000e 0, #640009 100%);
  }
}

.TESTING, .STAGING {
  .navbar-ncs{
    background-image: -webkit-linear-gradient(top, #d86d00 0, #a55300 100%);
    background-image: -o-linear-gradient(top, #d86d00 0, #a55300 100%);
    background-image: linear-gradient(to bottom, #d86d00 0, #a55300 100%);
  }
}