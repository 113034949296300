
.fa-csg {
  font-family: 'ncs-icons'; // shortening font declaration
  &:before {
    content: "\e601";
  }
}

.fa-usa-map {
  font-family: 'ncs-icons';
  &:before {
    content: "\e902";
  }
}
.fa-ncs {
  font-family: 'ncs-icons';
  &:before {
    content: "\e901";
  }
}
.fa-ncs-hex {
  font-family: 'ncs-icons';
  &:before {
    content: "\e900";
  }
}

.fa-ucc:before { content: fa-content($fa-var-lock); }
.fa-coll:before { content: fa-content($fa-var-dollar-sign); }
.fa-er:before { content: fa-content($fa-var-graduation-cap); }