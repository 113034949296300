
@font-face {
  font-family: 'ncs-icons';
  src:url('#{$ncs-icons-path}#{$ncs-icons-file}.eot?-iggnh');
  src:url('#{$ncs-icons-path}#{$ncs-icons-file}.eot?#iefix-iggnh') format('embedded-opentype'),
  url('#{$ncs-icons-path}#{$ncs-icons-file}.woff?-iggnh') format('woff'),
  url('#{$ncs-icons-path}#{$ncs-icons-file}.ttf?-iggnh') format('truetype'),
  url('#{$ncs-icons-path}#{$ncs-icons-file}.svg?-iggnh#ncs-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}